import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'input', 'form']

  clear (evt) {
    evt.preventDefault()
    evt.stopPropagation()

    const hiddenFieldId = evt.target.dataset.hiddenFieldId
    const hiddenField = document.getElementById(hiddenFieldId)
    if (hiddenField) {
      hiddenField.value = ''
    }
  }

  clearAndSubmit (evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.clear(evt)
    this.formTarget.requestSubmit()
  }
}
